import { initializeApp } from "firebase/app";
import 'firebase/firestore'
import { getFirestore } from "firebase/firestore/lite"

const firebaseConfig = {
    apiKey: "AIzaSyBj7ny1fhqk3cklHUtlxV_Yb9rBYVoeeQI",
    authDomain: "plauti-parking-app.firebaseapp.com",
    projectId: "plauti-parking-app",
    storageBucket: "plauti-parking-app.appspot.com",
    messagingSenderId: "1087791822419",
    appId: "1:1087791822419:web:e9dfec8a83c44a7b2c78a6",
    databaseURL: "https://dagenteller.firebaseio.com"
};

const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
