import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

import Home from '../views/Home.vue'
import Login from "@/views/Login";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!store.getters['user/isAuthenticated']) {
        next({ name: 'Login' });
      } else {
        next();
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['user/isAuthenticated']) {
        next({ name: 'Home' });
      } else {
        next();
      }
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
