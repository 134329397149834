<template>
  <div>
    <div class="flex justify-center">
      <div class="text-2xl leading-10 mr-32">
        Plauti park app
      </div>
      <t-button @click="logout">Signout</t-button>
    </div>
    <div class="grid grid-cols-7 gap-6 divide-x divide-gray-200 mt-6" v-if="spots.length">
      <div class="col-span-1">

      </div>
      <div class="col-span-2 pl-6">

        <div class="text-center">
          <div class="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>

          </div>
          <div class="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            <button v-for="(date, index) in dates" :key="date.format('x')" type="button" @click="selectDate(date)"
                    :class="[
                        'relative py-1.5 hover:bg-blue-100 focus:z-10 bg-white',
                         date.isBefore(today) && 'bg-gray-50 hover:bg-gray-50 cursor-default',
                         index === 0 && 'rounded-tl-lg',
                         index === 6 && 'rounded-tr-lg',
                         index === dates.length - 7  && 'rounded-bl-lg',
                         index === dates.length - 1 && 'rounded-br-lg',
                         isFull(date) && 'bg-orange-400'
                        ]">
              <time :class="[
                  'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                   date.isSame(selectedDate) && 'bg-orange-400 text-white',
                   date.isSame(today) && 'bg-gray-300'
                  ]">{{ date.format('DD') }}</time>

              <div v-if="!date.isSame(selectedDate)" class="absolute bottom-0,5 flex justify-center left-0 right-0">
                <div v-for="reservation in getReservationsDate(date)" :key="reservation.id" class="bg-orange-400 w-1 h-1 rounded  mx-0.5"></div>
              </div>

            </button>
          </div>
        </div>

        <div class="mt-10">
          <span class="text-xl text-gray-700">Reserved spots</span>

          <div>
            <div v-if="getReservationsDate().length === 0" class="my-5 text-gray-400 text-sm">
              There are no reservations on <span class="text-orange-400">{{ selectedDate.format('dddd, MMMM Do')}}</span>. Select your desired parkingspot to make a reservation.
            </div>
            <ol v-else class="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
              <li class="py-4 sm:flex" v-for="reservation in getReservationsDate()" :key="reservation.uid">
                <time datetime="2022-01-17" class="w-28 flex-none">Spot {{ getSpot(reservation.spot).name }}</time>
                <p class="flex-auto font-semibold text-gray-900" :class="{'text-orange-500':reservation.userId === userId}">{{ reservation.user }}</p>
                <p class="flex-none sm:ml-6" v-if="canDelete(reservation)">
                  <span class="hover:text-red-500 cursor-pointer" @click="setReservationToDelete(reservation)">delete</span>
                </p>
              </li>

            </ol>
          </div>
        </div>

        <div class="mt-10" v-if="selectedDate.isSameOrAfter(today)">
          <span class="text-xl text-gray-700">Reserve your spot</span>

          <div v-if="freeSpots !== 0">
            <div v-if="hasReservationUser">
              <span class="text-red-500 my-5 text-sm">You already made a reservation for this day.</span>
            </div>
            <div v-else-if="!hasReservationUser && selectedSpot">
              <dl>
                <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt class=" text-sm text-gray-500">
                    Name
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ username }}
                  </dd>
                  <dt class=" text-sm text-gray-500">
                    E-mailaddress
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ email }}
                  </dd>
                  <dt class=" text-sm text-gray-500">
                    Date
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ selectedDate.format('dddd, MMMM Do YYYY') }}
                  </dd>
                  <dt class=" text-sm text-gray-500">
                    Parkingsport
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{ selectedSpot.name }}
                  </dd>
                </div>
              </dl>

              <div class="flex justify-center">
                <t-button variant="success" @click="reserve">Reserve your spot now</t-button>
              </div>
            </div>
            <div v-else>
              <span class="my-5 text-gray-400 text-sm">
                Select your desired parkingspot to make a reservation.
              </span>
            </div>
          </div>
          <div v-else>
            <span class="text-red-500 my-5 text-sm">There are no more free spots left... good luck next time!</span>
          </div>
        </div>


      </div>

      <div class="col-span-3 pl-6">
        <div class="w-2/3 bg-slate-400 map h-192 mx-auto">
          <div class="spot" :class="[`${spot.position}-0`,`top-${spot.number * 12}`,{'bg-orange-400': hasReservationSpot(spot), 'bg-green-400':spot.selected,'cursor-pointer hover:bg-slate-300':spot.available && !hasReservationSpot(spot)}]" v-for="spot in spots" :key="`${spot.position}spot${spot.number}`" @click="!hasReservationSpot(spot) ? selectSpot(spot) : () => {}">
            <span class="text-slate-600 w-12 transform absolute text-xs top-4 text-center" :class="{'-rotate-90  -right-3':spot.position === 'left','rotate-90 -left-3':spot.position === 'right'}">{{ spot.company }}</span>

            <span v-if="spot.name" class="text-white w-12 transform absolute text-sm font-bold top-4 text-center" :class="{'-rotate-90  right-3':spot.position === 'left','rotate-90 left-3':spot.position === 'right'}">{{ spot.name }}</span>

            <img v-if="spot.car" :src="spot.car" :class="{'rotate-180 transform right-0 absolute':spot.position === 'right'}" class="h-full" />
          </div>

          <div class="pilar left-32 top-23 w-4 h-2 bg-white absolute"></div>
          <div class="pilar left-32 top-47 w-4 h-2 bg-white absolute"></div>
          <div class="pilar left-32 top-71 w-4 h-2 bg-white absolute"></div>
          <div class="pilar left-32 top-95 w-4 h-2 bg-white absolute"></div>
          <div class="pilar left-32 top-119 w-4 h-2 bg-white absolute"></div>
          <div class="pilar left-32 top-143 w-4 h-2 bg-white absolute"></div>
          <div class="pilar left-32 top-167 w-4 h-2 bg-white absolute"></div>

          <div class="pilar right-24 top-23 w-4 h-2 bg-white absolute"></div>
          <div class="pilar right-24 top-47 w-4 h-2 bg-white absolute"></div>
          <div class="pilar right-24 top-71 w-4 h-2 bg-white absolute"></div>
          <div class="pilar right-24 top-95 w-4 h-2 bg-white absolute"></div>
          <div class="pilar right-24 top-120 w-4 h-1 bg-white absolute"></div>
          <div class="pilar right-24 top-143 w-4 h-2 bg-white absolute"></div>
          <div class="pilar right-24 top-167 w-4 h-2 bg-white absolute"></div>

          <div class="wall left-0 w-16 h-1 top-95 bg-white absolute"></div>
          <div class="wall left-0 w-16 h-2 top-119 bg-white absolute"></div>

          <div class="wall right-0 w-20 h-12 top-108 bg-white absolute"></div>
          <div class="wall right-0 w-36 h-1 top-108 bg-white absolute"></div>
          <div class="wall right-0 w-36 h-1 top-119 bg-white absolute"></div>
          <div class="wall right-36 w-1 h-12 top-108 bg-white absolute"></div>
          <span class="absolute top-111 right-20 text-white w-24 transform absolute text-sm font-bold text-center rotate-90">Lift</span>
        </div>


      </div>
      <div class="col-span-1"></div>


    </div>
    <div v-if="!spots.length && !loading" class="text-red-600 p-20 text-center">
      <p class="text-2xl">Oops.. something went wrong...</p>
      <button @click="reload" type="button" class="mt-20 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Reload</button>
    </div>
    <ModalDelete :item="reservationToDelete" @ok="deleteReservation" @cancel="cancelDelete" v-if="reservationToDelete" />
    <ModalError :error="error" v-if="error" @ok="error = null" />

  </div>
</template>

<script>

import ModalDelete from "@/components/ModalDelete";
import ModalError from "@/components/ModalError";
export default {
  name: 'Home',
  components: {ModalError, ModalDelete},
  data() {
    return {
      today: (this.$moment()).startOf('day'),
      selectedDate: (this.$moment()).startOf('day'),
      dates: [],
      error: null,
      spots: [
      ],
      reservations: [],
      reservationToDelete: null,
      loading: true
    }
  },
  computed: {
    selectedSpot() {
      return this.spots.find(s => s.selected === true);
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    email() {
      return (this.user) ? this.user.email : '';
    },
    username() {
      return (this.user) ? this.user.displayName : '';
    },
    userId() {
      return (this.user) ? this.user.uid : '';
    },
    freeSpots() {
      let spots = this.spots.filter(s => {
        return s.available === true && !this.hasReservationSpot(s)
      });
      return spots.length;
    },
    hasReservationUser() {
      return this.reservations.find(r => this.$moment(r.date).isSame(this.selectedDate) && r.userId === this.userId);
    },
  },
  methods: {
    reload() {
      document.location.reload();
    },
    cancelDelete() {
      this.reservationToDelete = null;
    },
    canDelete(reservation) {
      return this.$moment(reservation.date).isSameOrAfter(this.today) && (reservation.userId === this.userId || this.user.admin === true);
    },
    login() {
      this.$store.dispatch('user/login');
    },
    logout() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push({
          name: 'Login'
        })
      });
    },
    getSpot(spotId) {
      return this.spots.find(s => s.id === spotId);
    },
    hasReservationSpot(spot) {
      return this.getReservationsDate().find(r => r.spot === spot.id) !== undefined
    },
    filterAvailable() {
      const spots = this.spots.filter(s => s.available === true);
      return spots.length;
    },
    rand(size = 4) {
      const rand = Math.floor(Math.random() * size) + 1;
      return rand;
    },
    carImage(rand) {
      let images = require.context('@/assets/', false, /\.png$/)
      return images('./car' + parseInt(rand) + '.png');
    },
    selectSpot(spot) {
      const spots = [ ... this.spots ];

      spots.map(s => s.selected = false);
      if (spot?.available) {
        let index = spots.findIndex(s => s.id === spot?.id);
        spots[index].selected = true;
      }
      this.spots = [...spots];
    },
    isFull(date) {
      return this.getReservationsDate(date).length === this.filterAvailable();
    },
    getReservationsDate(date = this.selectedDate) {
      return this.reservations.filter(r => {
        return r.date === date.format('YYYY-MM-DD')
      });
    },
    selectDate(date) {
      this.fillGarage();
      this.selectedDate = date;
      this.selectSpot(null);
    },
    fillGarage() {
      this.spots.map(s => {
        if (s.available === false && s.company !== 'Plauti') {
          s.car = (this.rand(3) % 2 !== 0) ? this.carImage(this.rand()) : null;
        }
      });
    },
    setReservationToDelete(reservation) {
      this.reservationToDelete = { ...reservation };
      this.reservationToDelete.spot = (this.getSpot(reservation.spot)).name;
    },
    async deleteReservation() {
      try {
        await this.$services['reservation'].delete(this.reservationToDelete.id);
        this.selectSpot(null);
        this.reservationToDelete = null;
        this.getReservations();
      } catch(e) {
        this.error = e;
      }
    },
    async reserve() {

      const spot = this.spots.find(s => s.selected === true);

      try {
        await this.$services['reservation'].insert({
          date: this.selectedDate.format('YYYY-MM-DD'),
          spot: spot.id,
          userId: this.userId,
          user: this.username,
          created: (new this.$moment()).format('YYYY-MM-DD HH:mm:ss')
        });
        this.selectSpot(null);
        this.getReservations();
      } catch(e) {
        this.error = e;
      }
    },
    async getReservations() {
      try {
        this.reservations = await this.$services['reservation'].getAll([{
          key: 'date',
          value: this.dates[0].format('YYYY-MM-DD'),
          operator: '>='
        }])
      } catch(e) {
        this.error = e;
      }
    }
  },
  async created() {
    this.loading = true;
    try {
      this.spots = await this.$services['spot'].getAll()
      this.spots.map(s => {
        s.selected = false;
      })
      this.fillGarage();

      let week = new this.$moment(this.today);
      week.startOf('week');

      for (let i = 0; i < 21; i++) {
        let date = new this.$moment(week).add(i, 'd');
        this.dates.push(date);
      }
      await this.getReservations();
      this.loading = false;
    } catch(e) {
      this.error = e;
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  @apply relative shadow-2xl;
  box-shadow: 0 10px 50px rgb(148,163,184);

  .spot {
    @apply border border-slate-500 border-opacity-50 w-36 h-12 absolute;
  }
}
</style>

