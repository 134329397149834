import {
    getAuth, onAuthStateChanged,
    signInWithPopup, OAuthProvider,
} from "firebase/auth";
import Vue from "vue";


export default {
    namespaced: true,
    state: {
        user: null,
        token: null,
        admin: false,
        error: null,
        attempts: 0,
        last_attempt: null,
        max_attempts: 3,
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        getUsername: state => {
            return state.user.displayName || `${state.user.firstname} ${state.user.lastname}`;
        },
        isAuthenticated: state => {
            return state.user !== null && state.user.isAnonymous === false;
        },
        getPicture: state => {
            return state.user.profilePicture || null
        },
        isAdmin: state => {
            return state.user.admin || false;
        },
        getError: state => {
            return state.error;
        }
    },
    mutations: {
        user: (state, user) => {
            state.user = {
                ...user,
                ...state.user
            };
        },
        token: (state, token) => {
            state.token = token;
        },
        logout: state => {
            state.user = null;
        },
        admin: (state, admin) => {
            state.admin = admin;
        },
        error: (state, error) => {
            state.error = error;
        }
    },
    actions: {
        detect: () => {
            return new Promise(resolve => {
                const auth = getAuth();
                onAuthStateChanged(auth, (user) => {
                   if (user) {
                       resolve(user);
                   } else {
                       resolve(user);
                   }
                });
            });
        },
        token: ({ commit }) => {
            return new Promise(resolve => {
                const auth = getAuth();
                auth.currentUser.getIdToken(true).then(response => {
                        commit("token", response);
                        resolve(true);
                    });
            });
        },
        login: ({ commit, dispatch, state }) => {
            return new Promise((resolve, reject) => {
                const provider = new OAuthProvider('microsoft.com');
                const auth = getAuth();

                provider.setCustomParameters({
                    tenant: '873fc6a8-bd9a-4cb3-91db-f3af4c1cfa73'
                });

                signInWithPopup(auth, provider)
                    .then(async (result) => {
                        // Microsoft credential is linked to the current user.
                        // IdP data available in result.additionalUserInfo.profile.

                        // Get the OAuth access token and ID Token
                        const credential = OAuthProvider.credentialFromResult(result);
                        const accessToken = credential.accessToken;

                        commit("token", accessToken);
                        commit("user", { ...result.user });

                        Vue.prototype.$services['user'].upsert(
                            result.user.uid,
                            { uid: result.user.uid, name: result.user.displayName, email: result.user.email})

                        resolve(true);
                    })
                    .catch((error) => {
                        dispatch("loginError", error).then(() => {
                            reject(state.error);
                        });
                    });
            });
        },


        loginError: ({ commit }, payload) => {
            return new Promise(resolve => {
                const error = {
                    message: ''
                };
                commit("error", null);

                // // Handle Errors here.
                // const errorCode = error.code;
                // const errorMessage = error.message;
                // // The email of the user's account used.
                // const email = error.email;
                // // The AuthCredential type that was used.
                // const credential = GoogleAuthProvider.credentialFromError(error);

                switch (payload.code) {
                    case 'auth/email-already-exists':
                        error.message = 'The provided email is already in use by an existing user. Each user must have a unique email.';
                        error.email = true;
                        break;
                    case 'auth/id-token-expired':
                        return 'The provided Firebase ID token is expired.';
                    case 'auth/id-token-revoked':
                        return 'The Firebase ID token has been revoked.';
                    case 'auth/insufficient-permission':
                        return 'The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.';
                    case 'auth/internal-error':
                        return 'The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our Bug Report support channel.';
                    case 'auth/invalid-argument':
                        return 'An invalid argument was provided to an Authentication method. The error message should contain additional information.';
                    case 'auth/invalid-creation-time':
                        return 'The creation time must be a valid UTC date string.';
                    case 'auth/invalid-display-name':
                        return 'The provided value for the displayName user property is invalid. It must be a non-empty string.';
                    case 'auth/invalid-email':
                        return 'The provided value for the email user property is invalid. It must be a string email address.';
                    case 'auth/invalid-email-verified':
                        return 'The provided value for the emailVerified user property is invalid. It must be a boolean.';
                    case 'auth/invalid-id-token':
                        return 'The provided ID token is not a valid Firebase ID token.';
                    case 'auth/invalid-password':
                    case 'auth/user-not-found':
                    case 'auth/wrong-password':
                        error.password = true;
                        error.message =  'Combinatie van e-mailadres en wachtwoord is niet geldig. Controleer uw gegevens of registreer.';
                        error.email = true;
                        break;
                    case 'auth/email-already-in-use':
                        error.message =  'E-mailadres is gekoppeld aan een ander account, probeer aan te melden of gebruik een ander e-mailadres.';
                        error.email = true;
                        break;
                    case "auth/account-exists-with-different-credential":
                        error.message =
                            "Account (e-mailadres) is al reeds gebruikt via andere registratie methode (handmatig, Google, Facebook).";
                        error.email = true;
                        break;
                    default:
                        error.message = payload.message || "Fout opgetreden, inloggen mislukt.";
                }

                commit("error", { ...error , origin: payload });
                resolve();
            });
        },
        logout: ({ commit }) => {
            return new Promise(resolve => {
                const auth = getAuth();
                auth.signOut().then(() => {
                    commit('logout');
                    resolve();
                });
            });
        }
    }
};
