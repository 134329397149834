<template>
  <div class="text-center">
    <t-button @click="login" class="mx-auto">Login with your Plauti Account</t-button>

    <img :src="carImage" class="mx-auto mt-9" />

  </div>
</template>

<script>
export default {
  name: "Login",
  computed: {
    carImage() {
      let images = require.context('@/assets/', false, /\.png$/);
      return images('./plauticar.png');
    }
  },
  methods: {
    login() {
      this.$store.dispatch('user/login').then(() => {
        this.$router.push({
          name: 'Home'
        })
      })
    }
  }
}
</script>