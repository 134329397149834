import Vue from 'vue'
import App from './App.vue'
import router from './router'

import './assets/tailwind.css';

import './plugins/index';
import './filters';
import store from './store'

Vue.config.productionTip = false

store.dispatch("user/detect").then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
});
