import Vue from "vue";
import createService from "@/services/Service";

const services = {
    install(Vue) {

        const services = {
            "spot":         createService("spot"),
            "reservation":  createService("reservation"),
            "user":         createService("user")
        }

        Vue.prototype.$services = services;
    }
}
Vue.use(services);